import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Box, Tab, Badge, Tooltip } from '@mui/material';
import { useApiGet } from '../../utils/httpClient';
import { getRosterMembers } from '../../endpoints/annualReports';
import DuesForm from './DuesForm';
import ActiveMembers from './ActiveMember';
import TransferOut from './TransferOut';
import InactiveMembers from './InactiveMembers';
import RosterToSort from './RosterToSort';
import CheckIcon from '@mui/icons-material/Check';
import ReportOffIcon from '@mui/icons-material/ReportOff';
import ErrorIcon from '@mui/icons-material/Error';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  formStates,
  formStatuses,
  memberStatuses,
} from '../../utils/variables';
import dayjs from 'dayjs';
import AlertItem from '../../Components/AlertItem';

export default function TabInfo({
  currentState,
  formStatusId,
  assigned,
  refetchParent,
  spdForm,
  resettingForm,
  formStateId,
  spdApprovable,
  parentAlertMessage,
  parentAlertSeverity,
  parentShowAlert,
  setParentKeepOpen,
  setFormSubmitOpen,
  setFormSubmitMessage,
  irsForm,
  isDisbanded
}) {
  let { id } = useParams();

  const calculateInitialTab = useCallback(() => {
    if (spdForm) {
      return formStatuses.DuesForm;
    }
    if (
      [formStatuses.Unassigned, formStatuses.NotStarted].includes(
        formStatusId
      ) &&
      assigned === true
    ) {
      return formStatuses.Unassigned;
    } else if (
      formStatusId === formStatuses.InactiveMembers &&
      assigned === true
    ) {
      return formStatuses.InactiveMembers;
    } else if (formStatusId === formStatuses.TransferOut && assigned === true) {
      return formStatuses.TransferOut;
    } else if (
      formStatusId === formStatuses.ActiveMembers &&
      assigned === true
    ) {
      return formStatuses.ActiveMembers;
    } else if (formStatusId === formStatuses.DuesForm && assigned === true) {
      return formStatuses.DuesForm;
    } else {
      return formStatuses.DuesForm;
    }
  }, [formStatusId, assigned, spdForm]);

  const [value, setValue] = useState(calculateInitialTab);
  const [totalInitiated, setTotalInitiated] = useState([]);
  const [totalReinstated, setTotalReinstated] = useState([]);
  const [duesEditable, setDuesEditable] = useState(false);
  const [activeEditable, setActiveEditable] = useState(false);
  const [inactiveEditable, setInactiveEditable] = useState(false);
  const [transferOutEditable, setTransferOutEditable] = useState(false);
  const [unassignedEditable, setUnassignedEditable] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [currentResetForm, setCurrentResetForm] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data, refetch } = useApiGet(
    ['roster-members', id],
    getRosterMembers,
    {
      enabled: true,
      retry: 1,
    },
    { id }
  );

  useEffect(() => {
    setValue(calculateInitialTab);
  }, [formStatusId, assigned, calculateInitialTab]);

  useEffect(() => {
    if (resettingForm !== currentResetForm) {
      refetch();
      setCurrentResetForm(resettingForm);
    }
    setCurrentResetForm(resettingForm);
  }, [resettingForm, refetch, setCurrentResetForm, currentResetForm]);

  const calcTotals = useCallback(() => {
    // Calculate totals for Dues Tab
    if (!data) {
      return;
    }
    let actionRein = data.filter(
      (member) =>
        member.reinstated_at &&
        dayjs(member.reinstated_at).isBetween(
          '2024-02-29',
          '2025-03-01',
          'day',
          '[)'
        )
    );
    let actionInit = data.filter(
      (member) =>
        member.initiation_date &&
        dayjs(member.initiation_date).isBetween(
          '2024-02-29',
          '2025-03-01',
          'day',
          '[)'
        )
    );
    setTotalInitiated(actionInit);
    setTotalReinstated(actionRein);
  }, [data]);

  const calcEditable = useCallback(() => {
    Object.values(formStatuses).forEach((statusId) => {
      let statusEditable = false;
      if (statusId === formStatusId && assigned === true) {
        statusEditable = true;
      } else if (
        currentState &&
        currentState.completed.includes(statusId) &&
        assigned === true
      ) {
        statusEditable = true;
      } else if (statusId === formStatuses.NotStarted && assigned === true) {
        statusEditable = true;
      }
      if (statusId === formStatuses.DuesForm) {
        setDuesEditable(statusEditable);
      } else if (
        statusId === formStatuses.ActiveMembers &&
        formStateId !== formStates.SpdSec
      ) {
        setActiveEditable(statusEditable);
      } else if (statusId === formStatuses.InactiveMembers) {
        if (formStateId === formStates.SpdSec) {
          setInactiveEditable(false);
        } else {
          setInactiveEditable(statusEditable);
        }
      } else if (statusId === formStatuses.TransferOut) {
        if (formStateId === formStates.SpdSec) {
          setTransferOutEditable(false);
        } else {
          setTransferOutEditable(statusEditable);
        }
      } else if (
        [formStatuses.Unassigned, formStatuses.NotStarted].includes(statusId)
      ) {
        setUnassignedEditable(statusEditable);
      }
    });
  }, [currentState, formStatusId, assigned, formStateId]);

  useEffect(() => {
    calcTotals();
  }, [data, calcTotals]);

  useEffect(() => {
    calcEditable();
  }, [currentState, formStatusId, assigned, calcEditable]);

  const tabIcon = (statusId, memberStatusIds, hideBadge = false) => {
    let badgeContent = data?.filter((member) =>
      memberStatusIds.includes(member.status_id)
    ).length;
    let statusIds = [statusId];
    if (statusId === formStatuses.Unassigned) {
      statusIds.push(formStatuses.NotStarted);
    }
    let icon = <ReportOffIcon color={'blue'} />;
    if (statusIds.includes(formStatusId) && assigned === true) {
      icon = <ErrorIcon sx={{ color: '#CC7722' }} />;
    } else if (
      currentState &&
      currentState.completed.includes(statusId) &&
      assigned === true
    ) {
      icon = <CheckIcon color={'green'} />;
    }

    if (hideBadge === true) {
      return icon;
    }
    return (
      <Badge badgeContent={badgeContent} color={'success'} showZero>
        {icon}
      </Badge>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {showAlert && (
          <AlertItem
            severity={alertSeverity}
            description={alertMessage}
            onClose={() => setShowAlert(false)}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: '100%' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="neat"
                TabIndicatorProps={{
                  sx: { height: '6px' },
                }}
              >
                {data?.filter(
                  (member) => member.status_id === memberStatuses.Unassigned
                ).length !== 0 && (
                  <Tab
                    label="Current Active Members"
                    value="b28842d9ad2641338faeead81752dd27"
                    icon={
                      <Tooltip
                        title="Active Members in the system at the start of the reporting process."
                        placement="top"
                      >
                        {tabIcon(formStatuses.Unassigned, [
                          memberStatuses.Unassigned,
                        ])}
                      </Tooltip>
                    }
                    iconPosition="start"
                  />
                )}
                {!spdForm && (
                  <Tab
                    label="Inactive Members"
                    value="70ab0a4e56f14072893c608b830aa6dc"
                    icon={
                      <Tooltip
                        title="List of inactve members in chapter for the last ten years."
                        placement="top"
                      >
                        {tabIcon(formStatuses.InactiveMembers, [
                          memberStatuses.Inactive,
                        ])}
                      </Tooltip>
                    }
                    iconPosition="start"
                  />
                )}
                {!spdForm && (
                  <Tab
                    label="No Longer In Chapter"
                    value="f05b1160c6e245cd9c5ff2542fe2ccf7"
                    icon={
                      <Tooltip
                        title="Certain members who are no longer part of chapter."
                        placement="top"
                      >
                        {tabIcon(formStatuses.TransferOut, [
                          memberStatuses.Other,
                          memberStatuses.NoProcessing,
                        ])}
                      </Tooltip>
                    }
                    iconPosition="start"
                  />
                )}
                {!spdForm && (
                  <Tab
                    label="Active Members As Of March 1"
                    value="f4f4ee067e3d4ab0b8ede6627e4ab0ac"
                    icon={
                      <Tooltip
                        title="Final Active Member list"
                        placement="top"
                      >
                        {tabIcon(formStatuses.ActiveMembers, [
                          memberStatuses.Active,
                        ])}
                      </Tooltip>
                    }
                    iconPosition="start"
                  />
                )}
                <Tab
                  label="Dues Form"
                  value="622be9722edf4de4b2b66e0e40bbeeef"
                  icon={
                    <Tooltip title="Annual Dues Form" placement="top">
                      {tabIcon(formStatuses.DuesForm, [], true)}
                    </Tooltip>
                  }
                  iconPosition="start"
                />
              </TabList>
            </Box>
            <TabPanel value={'b28842d9ad2641338faeead81752dd27'}>
              <RosterToSort
                rowData={data?.filter(
                  (member) => member.status_id === memberStatuses.Unassigned
                )}
                refetch={refetch}
                refetchParent={refetchParent}
                editable={unassignedEditable}
              />
            </TabPanel>
            <TabPanel value={'70ab0a4e56f14072893c608b830aa6dc'}>
              <InactiveMembers
                rowData={data?.filter(
                  (member) => member.status_id === memberStatuses.Inactive
                )}
                refetch={refetch}
                editable={inactiveEditable}
                refetchParent={refetchParent}
                formStateId={formStateId}
                assigned={assigned}
                currentStatusId={formStatusId}
                alertOpen={() => setShowAlert(true)}
                alertMessage={(message) => setAlertMessage(message)}
                alertSeverity={(severity) => setAlertSeverity(severity)}
              />
            </TabPanel>
            <TabPanel value={'f05b1160c6e245cd9c5ff2542fe2ccf7'}>
              <TransferOut
                rowData={data?.filter((member) =>
                  [
                    memberStatuses.Other,
                    memberStatuses.NoProcessing,
                  ].includes(member.status_id)
                )}
                refetch={refetch}
                editable={transferOutEditable}
                refetchParent={refetchParent}
                formStateId={formStateId}
                assigned={assigned}
                alertOpen={() => setShowAlert(true)}
                alertMessage={(message) => setAlertMessage(message)}
                alertSeverity={(severity) => setAlertSeverity(severity)}
                currentStatusId={formStatusId}
              />
            </TabPanel>
            <TabPanel value={'f4f4ee067e3d4ab0b8ede6627e4ab0ac'}>
              <ActiveMembers
                rowData={data?.filter(
                  (member) => member.status_id === memberStatuses.Active
                )}
                refetch={refetch}
                editable={activeEditable}
                refetchParent={refetchParent}
                formStateId={formStateId}
                assigned={assigned}
                alertOpen={() => setShowAlert(true)}
                alertMessage={(message) => setAlertMessage(message)}
                alertSeverity={(severity) => setAlertSeverity(severity)}
                setFormSubmitOpen={(value) => setFormSubmitOpen(value)}
                setFormSubmitMessage={(message) =>
                  setFormSubmitMessage(message)
                }
                parentAlertOpen={() => parentShowAlert(true)}
                parentAlertMessage={(message) => parentAlertMessage(message)}
                parentAlertSeverity={(severity) =>
                  parentAlertSeverity(severity)
                }
                parentSetFormSubmitOpen={(value) => setFormSubmitOpen(value)}
                parentSetParentKeepOpen={(value) => setParentKeepOpen(value)}
                parentSetFormSubmitMessage={(message) =>
                  setFormSubmitMessage(message)
                }
                currentStatusId={formStatusId}
                isDisbanded={isDisbanded}
              />
            </TabPanel>
            <TabPanel value={'622be9722edf4de4b2b66e0e40bbeeef'}>
              <DuesForm
                activeMembers={data?.filter(
                  (member) => member.status_id === memberStatuses.Active
                )}
                editable={duesEditable}
                refetchParent={refetchParent}
                totalInitiated={totalInitiated}
                totalReinstated={totalReinstated}
                irsForm={irsForm}
                spdApprovable={spdApprovable}
                alertOpen={() => parentShowAlert(true)}
                alertMessage={(message) => parentAlertMessage(message)}
                alertSeverity={(severity) => parentAlertSeverity(severity)}
                setFormSubmitOpen={(value) => setFormSubmitOpen(value)}
                setParentKeepOpen={(value) => setParentKeepOpen(value)}
                setFormSubmitMessage={(message) =>
                  setFormSubmitMessage(message)
                }
                formStateId={formStateId}
                formStatusId={formStatusId}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
}
