import React, { useEffect, useState } from 'react';
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import {
  getRosterMemberHistory,
  getRosterHistory,
  getIrsHistory,
} from '../endpoints/annualReports';
import { useApiGet } from '../utils/httpClient';
import dayjs from 'dayjs';
import { TableVirtuoso } from 'react-virtuoso';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 650,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

const columns = [
  {
    width: 50,
    label: 'Updated At',
    dataKey: 'updated_at_string',
    date: true,
  },
  {
    width: 50,
    label: 'Updated By',
    dataKey: 'updated_by',
  },
  {
    width: 50,
    label: 'Field',
    dataKey: 'field_updated',
  },
  {
    width: 100,
    label: 'Old Value',
    dataKey: 'old_value',
  },
  {
    width: 100,
    label: 'New Value',
    dataKey: 'new_value',
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      size="small"
      {...props}
      sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
    />
  ),
  TableHead: React.forwardRef((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={'right'}
          style={{ width: column.width }}
          sx={{
            backgroundColor: 'background.paper',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default function HistoryModal({
  modalOpen,
  memberId,
  rosterId,
  irsId,
  setModalOpen,
}) {
  const [data, setData] = useState(null);

  const { data: memberHistory, refetch: refetchMemberHisotry } = useApiGet(
    ['member-history'],
    getRosterMemberHistory,
    {
      enabled: memberId && modalOpen ? true : false,
    },
    {
      id: memberId,
    }
  );

  const { data: rosterHistory, refetch: refetchRosterHistory } = useApiGet(
    ['roster-history'],
    getRosterHistory,
    {
      enabled: rosterId && modalOpen ? true : false,
    },
    {
      id: rosterId,
    }
  );

  const { data: irsHistory, refetch: refetchIrsHistory } = useApiGet(
    ['irs-history'],
    getIrsHistory,
    {
      enabled: irsId && modalOpen ? true : false,
      retry: 1,
    },
    {
      id: irsId,
    }
  );

  useEffect(() => {
    if (modalOpen) {
      if (memberHistory) {
        setData(memberHistory);
      } else if (rosterHistory) {
        setData(rosterHistory);
      } else if (irsHistory) {
        setData(irsHistory);
      }
    }
  }, [memberHistory, rosterHistory, irsHistory, modalOpen]);

  useEffect(() => {
    if (modalOpen) {
      if (memberId) {
        refetchMemberHisotry();
      }
      if (rosterId) {
        refetchRosterHistory();
      }
      if (irsId) {
        refetchIrsHistory();
      }
    }
  }, [
    modalOpen,
    refetchIrsHistory,
    memberId,
    rosterId,
    irsId,
    refetchMemberHisotry,
    refetchRosterHistory,
  ]);

  const rowContent = (_index, row) => {
    return (
      <React.Fragment>
        {columns
          .filter((item) => !item.skip)
          .map((column) => (
            <TableCell key={column.dataKey} align={'right'}>
              {column.date
                ? dayjs(row[column.dataKey]).format('MMM DD hh:mm A')
                : row[column.dataKey]}
            </TableCell>
          ))}
      </React.Fragment>
    );
  };

  const handleClose = () => {
    setData(null);
    setModalOpen(false);
  };

  console.log(rosterHistory)
  console.log(modalOpen)
  console.log(rosterId)
  console.log(data)

  return (
    <Modal
      open={modalOpen}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container>
          <Grid item xs={12} align={'right'}>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={12} align={'center'}>
            <Typography
              variant="h5"
              component="h2"
              color={'primary'}
              paddingBottom={2}
            >
              Change History (Descending Order)
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} padding={2}>
            <Box sx={{ height: 400, width: 950 }}>
              {data && (
                <TableVirtuoso
                  data={data}
                  components={VirtuosoTableComponents}
                  fixedHeaderContent={fixedHeaderContent}
                  itemContent={rowContent}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
