import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { useApiGet, useApiSend } from './utils/httpClient';
import logo from './assets/PEO_InformalLogo_Color.png';
import { getMe, logout } from './endpoints/login';
import { jwtDecode } from 'jwt-decode';
import { applications } from './utils/variables';
import { KeyboardArrowDown } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuDrawer from './Drawer';

export default function SiteAppBar() {
  const { data } = useApiGet(['refresh'], getMe, {
    enabled: localStorage.getItem('user_id') ? true : false,
    retry: 0,
    refetchInterval: 60 * 1000, // The data will be refetched every 1 minute
  });
  const showFormLinks = localStorage.getItem('apps')
    ? localStorage.getItem('apps').includes(applications.ExecAppBar)
      ? true
      : false
    : false;

  useEffect(() => {
    if (data) {
      localStorage.setItem('token', data.access_token);
      const decoded = jwtDecode(data.access_token);
      localStorage.setItem('user_id', decoded.sub);
      localStorage.setItem('first_name', decoded.first_name);
      localStorage.setItem('last_name', decoded.last_name);
      localStorage.setItem('apps', decoded.applications);
    }
  }, [data]);

  const { mutate } = useApiSend(
    logout,
    () => {
      let email = localStorage.getItem('email');
      localStorage.clear();
      if (email) {
        localStorage.setItem('email', email);
      }
      window.location.href = '/login';
    },
    (error) => console.error(error)
  );

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElAnnualReport, setAnchorElAnnualReport] = useState(null);
  const [anchorElResources, setAnchorElResources] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenAnnualReportMenu = (event) => {
    setAnchorElAnnualReport(event.currentTarget);
  };

  const handleOpenResourcesMenu = (event) => {
    setAnchorElResources(event.currentTarget);
  };

  const handleCloseResourcesMenu = (linkType = null) => {
    setAnchorElResources(null);
    switch (linkType) {
      case 'reports':
        window.location.href = '/reports';
        break;
      case 'training':
        window.location.href = '/training';
        break;
      case 'documents':
        window.location.href = '/documents';
        break;
      case 'dashboard':
        window.location.href = '/';
        break;
      case 'contact-us':
        window.location.href =
          'https://peointernational.formstack.com/forms/annual_report_request_form';
        break;
      default:
        break;
    }
  };
  const handleCloseARMenu = (linkType) => {
    setAnchorElAnnualReport(null);
    switch (linkType) {
      case 'roster':
        window.location.href = '/roster';
        break;
      case 'irs':
        window.location.href = '/irs';
        break;
      default:
        break;
    }
  };

  const handleLogOut = (e) => {
    setAnchorElUser(null);
    e.preventDefault();
    mutate();
  };

  const handleCloseUserMenu = (linkType) => {
    setAnchorElUser(null);
    switch (linkType) {
      case 'profile':
        window.location.href = '/users/' + localStorage.getItem('user_id');
        break;
      case 'release-notes':
        window.location.href = '/release-notes';
        break;
      case 'user-mgt':
        window.location.href = '/users';
        break;
      default:
        break;
    }
  };

  const isApprovedUser = localStorage.getItem('apps')
    ? localStorage.getItem('apps').includes('12345')
    : false;

  const handleCloseDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <AppBar position="static" color="white">
      {localStorage.getItem('user_id') && (
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {isApprovedUser && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setOpenDrawer(!openDrawer)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Button onClick={() => handleCloseResourcesMenu('dashboard')}>
              <Box
                component="img"
                sx={{
                  height: 40,
                  width: 85,
                }}
                alt="Logo."
                src={logo}
              />
            </Button>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'right',
              }}
            >
              <MenuItem key={'forms'}>Forms</MenuItem>
            </Box>
            {showFormLinks && (
              <Box
                sx={{
                  flexGrow: 0,
                  display: { xs: 'none', md: 'flex' },
                  justifyContent: 'right',
                }}
              >
                <MenuItem key="ar" onClick={handleOpenAnnualReportMenu}>
                  {'Annual Reports'}
                  <KeyboardArrowDown fontSize="small" />
                </MenuItem>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar-ar"
                  anchorEl={anchorElAnnualReport}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElAnnualReport)}
                  onClose={handleCloseARMenu}
                >
                  <MenuItem
                    key={'roster_dues'}
                    onClick={() => handleCloseARMenu('roster')}
                  >
                    <Typography textAlign="center">
                      {'Roster / Dues'}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    key={'irs-lc'}
                    onClick={() => handleCloseARMenu('irs')}
                  >
                    <Typography textAlign="center">{'IRS-LC'}</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            )}

            <Box
              sx={{
                flexGrow: 0,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'right',
              }}
            >
              <MenuItem key="resources" onClick={handleOpenResourcesMenu}>
                {'Resources'}
                <KeyboardArrowDown fontSize="small" />
              </MenuItem>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar_recources"
                anchorEl={anchorElResources}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElResources)}
                onClose={handleCloseResourcesMenu}
              >
                <MenuItem
                  key={'report'}
                  onClick={() => handleCloseResourcesMenu('reports')}
                >
                  <Typography textAlign="center">{'Reports'}</Typography>
                </MenuItem>
                <MenuItem
                  key={'documents'}
                  onClick={() => handleCloseResourcesMenu('documents')}
                >
                  <Typography textAlign="center">{'Documents'}</Typography>
                </MenuItem>
                <MenuItem
                  key={'training'}
                  onClick={() => handleCloseResourcesMenu('training')}
                >
                  <Typography textAlign="center">{'Training'}</Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'right',
              }}
            >
              <MenuItem
                key={'contact-us'}
                onClick={() => handleCloseResourcesMenu('contact-us')}
              >
                Contact Us
              </MenuItem>
            </Box>

            <Box sx={{ flexGrow: 0, marginLeft: 2 }}>
              <Tooltip title="Open settings">
                <MenuItem key="profile" onClick={handleOpenUserMenu}>
                  {localStorage.getItem('first_name')}{' '}
                  <KeyboardArrowDown fontSize="small" />
                </MenuItem>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  key={'release-notes'}
                  onClick={() => handleCloseUserMenu('release-notes')}
                >
                  <Typography textAlign="center">{'Release Notes'}</Typography>
                </MenuItem>
                {localStorage
                  .getItem('apps')
                  .includes(applications.UserManagement) && (
                  <MenuItem
                    key={'user_management'}
                    onClick={() => handleCloseUserMenu('user-mgt')}
                  >
                    <Typography textAlign="center">
                      {'User Management'}
                    </Typography>
                  </MenuItem>
                )}
                <MenuItem
                  key={'profile'}
                  onClick={() => handleCloseUserMenu('profile')}
                >
                  <Typography textAlign="center">{'Profile'}</Typography>
                </MenuItem>
                <MenuItem
                  key={'contact-us'}
                  onClick={() => handleCloseResourcesMenu('contact-us')}
                >
                  Contact Us
                </MenuItem>

                <MenuItem key={'logout'} onClick={handleLogOut}>
                  <Typography textAlign="center">{'Log Out'}</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      )}
      <MenuDrawer
        open={openDrawer}
        toggleDrawer={handleCloseDrawer}
        logout={(e) => handleLogOut(e)}
      />
    </AppBar>
  );
}
