import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Box, Link, Divider } from '@mui/material';
import { useApiGet } from '../../utils/httpClient';
import { getTrainingDetail } from '../../endpoints/training';
import ReactPlayer from 'react-player';
import PageHeader from '../../Components/PageHeader';
import trainingLogo from '../../assets/trainingcopy.png';

export default function TrainingDetail() {
  let { id } = useParams();
  const { data } = useApiGet(
    ['training-detail', id],
    getTrainingDetail,
    { enabled: id ? true : false },
    { id }
  );

  return data ? (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader title={data.name} />
      </Grid>
      <Grid container item xs={12} padding>
        <Grid item xs={12}>
          <ReactPlayer
            className="react-player"
            width="100%"
            height='70vh'
            url={data.video_url}
            playsinline={true}
            controls={true}
          />
          <Link padding>Transcript</Link>
        </Grid>
      </Grid>
      <Grid item xs={12} padding={3}>
        <Divider />
      </Grid>
      <Grid item container xs={12} padding>
        <Grid item xs={12} padding>
          <Typography variant="h6" component={'div'} fontWeight={'bold'}>
            Related Trainings
          </Typography>
        </Grid>
        {data.related_trainings.map((training) => (
          <Grid
            item
            key={training.id}
            xs={4}
            sm={3}
            md={2}
            textAlign={'center'}
            paddingLeft={2}
            paddingRight={2}
          >
            <Box
              onClick={() =>
                (window.location.href = `/training/${training.id}`)
              }
              component="img"
              src={training.icon_image_url || trainingLogo}
              sx={{ width: '100%', height: 'auto' }}
            />
            <Typography variant="body1" component={'div'}>
              {training.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  ) : null;
}
