import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  TextField,
  Card,
  CardMedia,
  CardContent,
} from '@mui/material';
import { useApiGet } from '../../utils/httpClient';
import { getTrainingList } from '../../endpoints/training';
import TrainingCategorySelector from '../../Components/TrainingCategorySelector';
import TrainingTopicSelector from '../../Components/TrainingTopicSelector';
import CheckIcon from '@mui/icons-material/Check';
import PageHeader from '../../Components/PageHeader';
import traingingLogo from '../../assets/trainingcopy.png';

const TrainingCard = ({ training }) => {
  let image = training.icon_image_url ? training.icon_image_url : traingingLogo;
  return (
    <Card
      onClick={() => (window.location.href = `/training/${training.id}`)}
      sx={{ display: 'flex', padding: 1, borderRadius: '16px' }}
    >
      <CardMedia
        image={image}
        sx={{
          width: 120,
          flexShrink: 0,
          backgroundColor: 'grey.200',
          borderRadius: '12px',
          boxShadow: '0 2px 8px 0 #c1c9d7, 0 -2px 8px 0 #cce1e9',
        }}
      />
      <CardContent sx={{ pl: 2, flex: 1 }}>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Grid item>
              <Typography variant="h6" component="div">
                {training.name}
              </Typography>
            </Grid>
            {training.user_completed && (
              <Grid item display={'flex'}>
                <Typography
                  variant="body2"
                  color={'success.main'}
                  fontWeight={'bold'}
                >
                  {'Completed'}
                </Typography>
                <CheckIcon color="success" fontSize="small" sx={{ pl: 1 }} />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary">
              {training.description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default function Training() {
  let [searchParams, setSearchParams] = useSearchParams();
  const updateSearchParams = useCallback(
    (key, value) => {
      if (!value || value === '' || value.length === 0) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState(searchParams.get('topics') ? [{id: searchParams.get('topics')}] : []);
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [delaySearch, setDelaySearch] = useState(
    searchParams.get('search') || ''
  );

  console.log('topics', topics);
  // need up update search params when search changes
  useEffect(() => {
    updateSearchParams('search', search);
  }, [search, updateSearchParams]);
  // need up update params when categories changes
  useEffect(() => {
    let temp = categories.map((category) => category.id);
    updateSearchParams('categories', temp);
  }, [categories, updateSearchParams]);
  // need up update params when topics changes
  useEffect(() => {
    let temp = topics.map((topic) => topic.id);
    updateSearchParams('topics', temp);
  }, [topics, updateSearchParams]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearch(delaySearch);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [delaySearch]);

  const { data } = useApiGet(
    ['training-list', search, categories, topics],
    getTrainingList,
    {
      enabled: true,
      retry: 1,
    },
    { search, categories, topics }
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="Training"
        />
      </Grid>
      <Grid item xs={12} padding>
        <Grid container>
          <Grid item xs={12} sm={4} padding={2}>
            <TextField
              id="search"
              label="Search"
              size="small"
              fullWidth
              value={delaySearch}
              onChange={(e) => setDelaySearch(e.target.value)}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={4} padding={2}>
            <TrainingTopicSelector
              value={topics}
              handleValueChange={(value) => setTopics(value)}
              label={'Training Topic'}
            />
          </Grid>
          <Grid item xs={12} sm={4} padding={2}>
            <TrainingCategorySelector
              value={categories}
              handleValueChange={(value) => setCategories(value)}
              label={'Training Category'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} padding={2}>
          {data &&
            data.map((training) => (
              <Grid item xs={12} padding>
                <TrainingCard training={training} key={training.id} />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
